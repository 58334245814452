import { clickContent } from "../Analitics/GoogleAnalytics4";
import { ContentList } from "./styles";
import { useRouter } from "next/router";
import ContentImage from "@/shared/ContentImage";
import { getImageByClippings } from "@/helpers/getImageByClippings";
import { getDataImageFromRelated } from "@/helpers/getFromRelated";

const ContentsLatest = ({ title,   related = {},datalatest, templateSize = "TwoxOneVisual", isPreview }) => {
    let newsToShow = ["TwoxOneVisual","TwoxOneNoImage", "TwoxOneFoto"].includes(templateSize) ? 6 : 11;
    const newsToDisplay = datalatest.slice(0, newsToShow);

    if (isPreview) {
        return (
            <ContentList>
                <li><img src="/img/dummy-ultimo-momento.png" /></li>
            </ContentList>
        );
    }
    const router = useRouter();
    return (
        <>
            <ContentList>
                <a className="titleLast" href="/ultimo-momento" alt="Último Momento">Último Momento</a>
                {
                    newsToDisplay.map((data) => {
                        const fecha = new Date(new Date(data.publishedDate).toLocaleString('en-US', { timeZone: 'America/Argentina/Buenos_Aires' }));
                        const minutes = fecha.getMinutes().toString();
                        const dataImageRelated = getDataImageFromRelated(data?.related ?? data);
                        return (
                            <li key={data._id}>

                                    <picture>
                                        <ContentImage 
                                        data={getImageByClippings(
                                            { desktop: ["square_sm"], mobile: ["square_sm"] },
                                            dataImageRelated?.clippings
                                        )} 
                                        alt={title} />
                                      </picture>
                                <div className="boxTextLatest">
                                    <span>{`${fecha.getHours()}:${minutes.length == 1 ? '0'+minutes : minutes }`}</span>
                                    <article>
                                        <a href={data.url} onClick={() => clickContent({contentType: data.title, contentCategory:'clarin', contentList:'ultimo-momento'},data.url,router.asPath)}>{data.title}</a>
                                    </article>
                                </div>
                            </li>
                        );
                    })
                }
            </ContentList>
        </>
    );
}
export default ContentsLatest;