import ContentsLatest from "@/shared/ContentsLatest";
import Separator from "@/shared/Separator";
import Banner from "@/widgets/Banner/Templates/Banner";
import {InnerCardL, InnerCardR, InnerCardM, Parent, Card} from "./style";
import { BannerCajaGris } from "@/shared/ContentBanner";
import {BannerSticky} from '@/shared/ContentBanner';
const Container5Pisos = (props) => {
  const { datalatest = [], link, sectionClass = "", title, index = 0, titleEnabled = true, marfeelEnabled = true, isPreview, title1, link1, title2, link2, title3, link3, title4, link4, linkImage } = props;

  const marfeelTitle = title ? "container4pisos-"+title?.normalize("NFD")
  .replace(/[\u0300-\u036f]/g, "")
  .replace(/\s+/g, '-')
  .toLowerCase() : "container4pisos";

  const titlesExtras = [];
  if(title1 && link1) titlesExtras.push({title: title1, link: link1})
  if(title2 && link2) titlesExtras.push({title: title2, link: link2})
  if(title3 && link3) titlesExtras.push({title: title3, link: link3})
  if(title4 && link4) titlesExtras.push({title: title4, link: link4})

  return (
    props.content?.length > 0 && <>
      {title && titleEnabled && <Separator title={title} link={link} sectionClass={sectionClass} extras={titlesExtras}linkImage={linkImage} />}
      <Parent {...(marfeelEnabled ? {'data-mrf-recirculation': marfeelTitle} : '')} className={`container4pisos ddsd  module-grid ${sectionClass}`}>
        <Card className="box1">
          <InnerCardL className="div1">{props.renderContainerWidgetCallback(index + 1,props.positionProperties?.[index]?.templateNews ?? "OnexOne", {
            containerName: "contenedor_6_pisos"
          })} </InnerCardL>
          <div className="div2">{props.renderContainerWidgetCallback(index + 2,props.positionProperties?.[index + 1]?.templateNews ?? "OnexOne", {
            containerName: "contenedor_6_pisos"
          })} </div>
          <InnerCardM className="div3"> {props.renderContainerWidgetCallback(index + 3,props.positionProperties?.[index + 2]?.templateNews ?? "TwoxOneVisual", {
            containerName: "contenedor_6_pisos"
          })}</InnerCardM>
          <InnerCardL className="div4"> {props.renderContainerWidgetCallback(index + 4,props.positionProperties?.[index + 3]?.templateNews ?? "OnexOne", {
            containerName: "contenedor_6_pisos"
          })}</InnerCardL>
          <InnerCardM className="div5"> {props.renderContainerWidgetCallback(index + 5,props.positionProperties?.[index + 4]?.templateNews ?? "Half", {
            containerName: "contenedor_6_pisos"
          })}</InnerCardM>
          <InnerCardM className="div6"> {props.renderContainerWidgetCallback(index + 6,props.positionProperties?.[index + 5]?.templateNews ?? "Half", {
            containerName: "contenedor_6_pisos"
            })}</InnerCardM>
          <InnerCardL className="div7 caja"><BannerCajaGris><Banner slotId={"caja6"} /></BannerCajaGris> </InnerCardL>
          <InnerCardM className="div8"> {props.renderContainerWidgetCallback(index + 7,props.positionProperties?.[index + 6]?.templateNews ?? "OnexOne", {
            containerName: "contenedor_6_pisos"
          })}</InnerCardM>
          <InnerCardL className="div9"> {props.renderContainerWidgetCallback(index + 8,props.positionProperties?.[index + 7]?.templateNews ?? "OnexOne", {
            containerName: "contenedor_6_pisos"
          })}</InnerCardL>
          <InnerCardM className="div10"> {props.renderContainerWidgetCallback(index + 9,props.positionProperties?.[index + 8]?.templateNews ?? "OnexOne", {
            containerName: "contenedor_6_pisos"
          })}</InnerCardM>
        </Card>
        <Card className="box2"><BannerSticky><Banner slotId={"caja0"} /></BannerSticky></Card>
        <div className="box3">
          <InnerCardM className={`div11 ${!["TwoxOneVisual","TwoxOneNoImage", "TwoxOneFoto"].includes(props.positionProperties?.[index + 2]?.templateNews) && "contentWrap"}`}><ContentsLatest isPreview={isPreview} datalatest={datalatest} templateSize={props.positionProperties?.[index + 2]?.templateNews ?? "OnexOne"}/></InnerCardM>
          <InnerCardM className="div12">{props.renderContainerWidgetCallback(index + 10,props.positionProperties?.[index + 9]?.templateNews ?? "OnexOne", {
            containerName: "contenedor_6_pisos"
          })} </InnerCardM>
          <InnerCardM className="div13"> {props.renderContainerWidgetCallback(index + 11,props.positionProperties?.[index + 10]?.templateNews ?? "OnexOne", {
            containerName: "contenedor_6_pisos"
          })}</InnerCardM>
          <InnerCardM className="div14"> <BannerCajaGris><Banner slotId={"caja1"} /></BannerCajaGris></InnerCardM>
        </div>
      </Parent>
    </>
  );
};
export default Container5Pisos;