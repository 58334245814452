import styled from "styled-components";
import { innerCardLefttMixin, innerCardRightMixin, innerMiddleMixin, cardMixin} from "../mixin"; 
import { Modulo4PisosGrid } from "../mixinContainer4Pisos"; 
import { breakpoints } from "@/shared/Constants";

//MODULO 4 pisos
export const Parent = styled.div`
    ${Modulo4PisosGrid}
    ${breakpoints.phone}{
            width:100%;
        } 
        .box3{
          .div14{
            max-width:300px;
            overflow:hidden;
            ${breakpoints.phone}{
              max-width:inherit;
            } 
          }
          
        }
       
`;

export const InnerCardL = styled.div`
  ${innerCardLefttMixin}
`;

export const InnerCardR = styled.div`
${innerCardRightMixin};
`;

export const InnerCardM = styled.div`
${innerMiddleMixin};
&.div11{
  height: 671px;
  overflow: hidden;
  &.contentWrap{
    height: 1012px;
  }
  &:after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 1px;
    background-color: #E0E0E0;
}
}
`;


export const Card = styled.div`
${cardMixin};
`;