import styled from "styled-components";
import { Font, Color, breakpoints, ColorDark } from "@/shared/Constants";





export const ContentList = styled.ul`
  display: flex;
  flex-direction: column;
  height: 100%;
  a.titleLast {
    color: ${Color.black};
    font-size: 20px;
    ${Font.sansBold};
    margin-bottom: 20px;
    ${breakpoints.darkMode} {
      [data-theme='dark'] & {
          color: ${ColorDark.backDarknew};
      }
    }
  }
  li {
    position: relative;
    display: flex;
    flex-direction: row;
    margin-bottom:30px; 
    .boxTextLatest {
      display:flex;
      flex-direction:column;
      flex-basis: fit-content;
      a {
      color: ${Color.black};
      font-size: 15px;
      line-height: 19px;
      ${Font.sansRegular};
      margin-left: 18px;
      letter-spacing: -0.3px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      text-decoration: none;
      ${breakpoints.darkMode} {
        [data-theme='dark'] & {
          color: ${ColorDark.textLight};
        }
      }
      ${breakpoints.phone} {
        font-size: 16px;
        line-height: 20px;
      }
    }
    span {
      font-size: 12px;
      display: flex;
      flex-direction: row;
      padding-bottom: 3px;
      ${Font.sansBold};
      ${breakpoints.darkMode} {
        [data-theme='dark'] & {
          color: ${ColorDark.textLight};
        }
      }
    }
    span:before {
      content: "";
      width: 5px;
      height: 5px;
      background: ${Color.black};
      display: inline-block;
      border-radius: 4px;
      margin-right: 13px;
      translate: 6px 2px;
      ${breakpoints.darkMode} {
        [data-theme='dark'] & {
          background: ${ColorDark.textLight};
        }
      }
    }
    span:after {
      content: "";
      width: 1px;
      height: 130%;
      display: inline-block;
      position: absolute;
      left: 78px;
      top: 3px;
      z-index: -1;
      border-left: 1px dotted #E0E0E0;
      ${breakpoints.darkMode} {
        [data-theme='dark'] & {
          border-left: 1px dotted ${ColorDark.greySeparator} ;
        }
      }
       }
    }
  picture{
    flex-basis: 90px;
    height:70px;
    display:block;
    flex-grow: 0;
      img{
        height:100%;
      }
    }
    &:last-child {
        span:after {
          display: none;
        }
      }
    }
`;
