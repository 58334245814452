import { Flag , FlagBox} from "./styles";
import ContentIcon from "@/shared/ContentIcon";



const ContentFlagIcon = ({nameIcon, titleLocation}) => {   
    return (
        <>
            <Flag>
               <FlagBox className="svg-box"><ContentIcon fill="#000" width="16.5px" height="17.5px" nameIcon={nameIcon} /></FlagBox> 
            </Flag>
        </>
    );
}

export default ContentFlagIcon;