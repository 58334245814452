import styled from "styled-components";
import { Color } from "@/shared/Constants";



export const Flag = styled.div `
    background: #fff;
    position: absolute;
    left:10px;
    top:10px;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    
div{
    display:flex;
    align-items: center;
    justify-content: center;
}



`;

export const FlagBox = styled.span ` 

position: relative;

`